import React, {useEffect, useState} from "react";
import s from "./Menu.module.css"
import {linear, Scrollchor} from "react-scrollchor";
import logo from './header-logo.svg'
import guarantee from './header-garanty.svg'

const Menu = () => {

    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 200);
        });
    }, []);

    return (
        <>
            <div className={s.top}>
                <div className={s.logoContainer}>
                    <img src={logo} alt=""/>
                    <p className={s.logoText}>Мельницы для производства<br/>порошковых материалов</p>
                </div>
                <div className={s.btnContainer}>
                    <Scrollchor to="selection" animate={{duration: 500, easing: linear}}>
                        <button className={s.topBtn}>Получить коммерческое предложение</button>
                    </Scrollchor>
                </div>
                <div className={s.topPros}>
                    <div className={s.topGuaranty}><img src={guarantee}/>Расширенная гарантия</div>
                    <div className={s.topGuaranty}><img src={guarantee}/>Доступный лизинг</div>
                </div>
                <div className={s.topPhone}>
                    <a href="tel:88002507096" style={{textDecoration: 'none'}}>
                        <p style={{fontSize: '24px', textDecoration: 'none', fontWeight: 'bolder', marginBottom: '0'}}>
                            8 (800) 250-70-96
                        </p>
                    </a>
                    <a href="#callbackwidget">
                        <p style={{marginTop: '0'}}>или закажите звонок</p>
                    </a>
                </div>
            </div>



            {/*<header className={s.topNavigation}>
                <nav className={s.menu}>
                    <li><Scrollchor to="payback" animate={{duration: 500, easing: linear}}>Соответствие</Scrollchor></li>
                    <li><Scrollchor to="about" animate={{duration: 500, easing: linear}}>О компании</Scrollchor></li>
                    <li><Scrollchor to="selection" animate={{duration: 500, easing: linear}}>Подбор</Scrollchor></li>
                    <li><Scrollchor to="scheme" animate={{duration: 500, easing: linear}}>Схема</Scrollchor></li>
                    <li><Scrollchor to="supplies" animate={{duration: 500, easing: linear}}>Поставки</Scrollchor></li>
                    <li><Scrollchor to="contacts" animate={{duration: 500, easing: linear}}>Контакты</Scrollchor></li>
                </nav>
            </header>
            <header className={scroll ? s.scrollNavigation + ' ' + s.scroll : s.scrollNavigation}>
                <nav className={s.menu}>
                    <li><Scrollchor to="payback" animate={{duration: 500, easing: linear}}>Соответствие</Scrollchor></li>
                    <li><Scrollchor to="about" animate={{duration: 500, easing: linear}}>О компании</Scrollchor></li>
                    <li><Scrollchor to="selection" animate={{duration: 500, easing: linear}}>Подбор</Scrollchor></li>
                    <li><Scrollchor to="scheme" animate={{duration: 500, easing: linear}}>Схема</Scrollchor></li>
                    <li><Scrollchor to="supplies" animate={{duration: 500, easing: linear}}>Поставки</Scrollchor></li>
                    <li><Scrollchor to="contacts" animate={{duration: 500, easing: linear}}>Контакты</Scrollchor></li>
                    <li className={s.navPhone}>
                        <a href="tel:88002507096" style={{textDecoration: 'none'}}>
                            <p style={{textDecoration: 'none', fontWeight: 'bolder', marginBottom: '0', marginTop: '0', fontSize: '21px'}}>
                                8 (800) 250-70-96
                            </p>
                        </a>
                        <a href="#callbackwidget">
                            <p style={{marginTop: '0', marginBottom: 0, textDecoration: 'underline', fontSize: '17px'}}>или закажите звонок</p>
                        </a>
                    </li>
                </nav>
            </header>*/}
        </>
    )

}

export default Menu;